exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beta-js": () => import("./../../../src/pages/beta.js" /* webpackChunkName: "component---src-pages-beta-js" */),
  "component---src-pages-documentation-js": () => import("./../../../src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-templates-document-js": () => import("./../../../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */)
}

